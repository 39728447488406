import { graphql, useStaticQuery } from "gatsby"

const useLegsTrikes = () => {

  const data = useStaticQuery(graphql`
    query {
      data: blockContentImageGrid( id: {eq: "b6d0f122-ac20-5f4f-880d-5fe2813a3d70"} ) {
        relationships {
          links: field_links {
            ...CtaContent
          }
        }
      }
    }
  `)


  return {
    data: data.data.relationships.links,
  }

}

export default useLegsTrikes
