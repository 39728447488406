import React from "react"
import Layout from "../components/layouts"
import SectionGrid3 from "../components/sections/grid/grid3"
import SectionBase from "../components/sections/base"
import HeroInner2 from "../components/sections/hero/inner2"
import Product2Card from "../components/cards/product2"
import useTopCategories from "../hooks/useTopCategories"
import SectionTopCategories from "../components/sections/top-categories"
import { graphql } from "gatsby"
import useLegsTrikes from "../hooks/useLegsTrikes"

const IndexPage = ({ data: { node, products } }) => {

  //console.log(node.id)

  const id = 'a62c5568-5601-5671-aee7-ccb5bc9474a1'

  return (
    <Layout>
      <Hero data={node.relationships.hero} />

      { node.id !== id && <Section2 data={products.nodes} title={node.section_title} id={node.id} /> }
      { node.id !== id && <div className="h-2 mx-4 bg-dark"></div>}

      { node.id === id && <Section2B title={node.section_title} id={node.id} /> }

      <Section1 />
    </Layout>
  )
}


const Hero = ({ data }) => {

  const { title, subtitle } = data
  const url = data.relationships.image?.localFile?.publicURL

  const action1 = data.relationships.actions[0]
  //const action2 = data.relationships.actions[1]

  let backgrounds = []
  backgrounds = data.relationships.images.map( (m) => ( m.localFile.publicURL  ))
  if( backgrounds.length === 0 ){
    backgrounds.push(url)
  }

  //const

  return (
    <HeroInner2
      title={title}
      //style={{ backgroundImage: `url("${url}")` }}
      backgrounds={backgrounds}
      description={subtitle}
      action1={action1}

    />
  )

}


const Section1 = () => {

  const items = useTopCategories()

  return (
    <SectionTopCategories
      items={items}
      //className="relative top-[-150px] bottom-[-150px]"
      className="mt-24 container"
    />
  )

}


const Section2 = ({ data, title, id }) => {

  const cards = data
    .filter(product => product?.relationships?.category?.id === id)
    .map((product, index) => (
      <Product2Card
      className="bg-white"
      classNameTitle="text-center"
      classNameHtml="text-center"
        key={index}
        image={{
          url: product?.relationships?.image?.localFile?.publicURL,
          alt: product?.image?.alt
        }}
        title={product.title}
        html={product?.relationships?.info?.description[0]?.processed}
        url={product.path.alias}
      />
    ))

  return (
    <SectionGrid3
      className=" py-12 lg:bg-dark"
      //classNameTitle="text-[55px] text-center "
      classNameGrid=" gap-x-16 gap-y-6 mx-4 lg:mx-32"
      //title={title}
    >
      {cards}
    </SectionGrid3>
  )

}

const Section2B = ({  title, id }) => {

  const {data} = useLegsTrikes()

  const cards = data.map((product, index) => (
      <div className="lg:text-white lg:p-5" key={index}>
        <a href={product.link.uri} target="__blank" className="space-y-8">
        <div>
          <img src={product.relationships.image.localFile.publicURL} alt={product.image.alt} />
        </div>
        <div className="text-center text-xl">
          {product.link.title}
        </div>
        </a>
      </div>
    ))

  return (
    <SectionBase
      className=" py-12 lg:bg-dark"
      //classNameTitle="text-[55px] text-center "
      classNameGrid=" gap-x-16 gap-y-6 mx-4 lg:mx-32"
      //title={title}
    >

      <div className="container">
        <div className="lg:flex lg:flex-row lg:justify-around">
          {cards}
        </div>
      </div>

    </SectionBase>
  )

}


export default IndexPage


export const query = graphql`
  query trikesCategories($id: String!) {
    node: taxonomyTermTrikes(id: {eq: $id }) {
      id
      title: name
      metatag {
        attributes {
        content
          name
        }
        tag
      }
      path{
        alias
      }
      section_title: field_cta_title
      description {
        processed
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }

      }
    }
    products: allNodeProduct {
      nodes {
        id
        title
        status
        path {
          alias
        }
        image: field_image {
          alt
        }
        relationships {
          info: field_paragraph_block {
            ...BlockContent
          }
          image: field_image {
            ...Image
          }
          category: field_category {
            id
            name
          }
        }
      }
    }
  }
`
