import * as React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import Card from './base'
import { Title } from "../atoms/titles"
import { Description, Html } from "../atoms/text"


const Product2Card = ({
  title,
  description,
  html,
  url,
  image,
  className,
  classNameTitle = '',
  classNameHtml = '',
  children,
  //style = {},
  ...props
}) => {

  //console.log(image)

  const imgStyle = { backgroundImage: `url("${image.url}")` }

  return (
    <Card className={className}>
      {/* <div className="w-full h-[200px] lg:h-[300px] bg-no-repeat bg-center bg-cover " style={imgStyle} /> */}
      <div className="w-full  lg:min-h-[400px] flex flex-col justify-end "  >
        <img src={image.url} alt={title} className="" />
      </div>
      <div className="p-4 lg:p-8">
        <Link className="hover:underline" to={url}>
          <Title className={"text-3xl lg:text-2xl text-center  leading-[2rem] font-bold " + classNameTitle }><span dangerouslySetInnerHTML={{__html:title}} /></Title>
        </Link>
        <Description>{description}</Description>
        <Html className={classNameHtml}>{html}</Html>

        {children}
      </div>
    </Card>
  )
}


Product2Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

Product2Card.defaultProps = {
  className: "",
};

const Product2CardExample = () => {

  return (
    <Product2Card
      title="product name"
      description="product description"
      //style={}

      image={{
        url: 'https://picsum.photos/960/450?random=40',
        alt: 'some thing'
      }}
      url='/product'
    />
  )
}

export default Product2Card
export { Product2CardExample }
